var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-2" },
    [
      _c("v-card-title", { staticClass: "text-h6 mb-2" }, [
        _vm._v(" Versions composition History "),
      ]),
      _c(
        "div",
        { staticClass: "d-flex flex-row" },
        [
          _c(
            "v-col",
            { attrs: { cols: 2 } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { color: "primary", vertical: "" },
                  model: {
                    value: _vm.tabl,
                    callback: function ($$v) {
                      _vm.tabl = $$v
                    },
                    expression: "tabl",
                  },
                },
                _vm._l(_vm.sortedPortfolioVersions, function (itemx, idx) {
                  return _c("v-tab", { key: idx }, [
                    _vm._v(" " + _vm._s(itemx["name"]) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: 10 } },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tabl,
                    callback: function ($$v) {
                      _vm.tabl = $$v
                    },
                    expression: "tabl",
                  },
                },
                _vm._l(_vm.sortedPortfolioVersions, function (itemx, idx) {
                  return _c(
                    "v-tab-item",
                    { key: idx },
                    [
                      _c("h5", [
                        _vm._v(
                          "date de création du " +
                            _vm._s(itemx["name"]) +
                            " : " +
                            _vm._s(itemx.date_version)
                        ),
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-simple-table", { attrs: { dense: "" } }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("NAME")]),
                                    _c("th", [_vm._v("ACTION")]),
                                    _c("th", [_vm._v("CLOSE")]),
                                    _c("th", [_vm._v("VALO")]),
                                    _c("th", [_vm._v("SOLIDITE")]),
                                    _c("th", [_vm._v("PERFORMANCE")]),
                                    _c("th", [_vm._v("SCORE")]),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [_vm._v("%")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    itemx.portfolioComposition,
                                    function (component, ids) {
                                      return _c(
                                        "tr",
                                        { key: ids },
                                        [
                                          [
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "font-size": ".8em",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "mr-1",
                                                    staticStyle: {
                                                      "font-size": "16px",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s() + " ")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      component.company.name.toUpperCase()
                                                    ) +
                                                    " "
                                                ),
                                                _c("small", [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        component.company.symbol
                                                      ) +
                                                      ")"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(component.nbr_action) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    parseFloat(
                                                      component.close
                                                    ).toFixed(3)
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    parseFloat(
                                                      component.valo
                                                    ).toFixed(3)
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    parseFloat(
                                                      component.solidite
                                                    ).toFixed(3)
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    parseFloat(
                                                      component.performance
                                                    ).toFixed(3)
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c("td", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    parseFloat(
                                                      component.score
                                                    ).toFixed(3)
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      parseFloat(
                                                        component.percentage
                                                      ).toFixed(2)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-row", [_c("v-col", [_c("hr")])], 1),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-simple-table", { attrs: { dense: "" } }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("Transaction Balance")]),
                                    _c("th", [_vm._v("Valuation")]),
                                    _c("th", [_vm._v("Global Valuation")]),
                                    _c("th", [_vm._v("index")]),
                                  ]),
                                ]),
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(itemx["transaction_balance"])
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(itemx["valuation"])),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(itemx["global_valuation"])),
                                    ]),
                                    _c("td", [_vm._v(_vm._s(itemx["index"]))]),
                                  ]),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }