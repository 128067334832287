












































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import { Portfolio } from "@/smartmsi";
import Flashable from "@/components/stock/Flashable.vue";

@Component({
    components: { Flashable },
    mixins: [formatter]
})
export default class VersionsPopup extends Vue {

    @Prop({ required: true }) portfolio!: Partial<Portfolio>;
    tabl = 0;
    @Watch('portfolio', { immediate: true, deep: true })
    onPortfolioChange(newVal: Partial<Portfolio>) {
        console.log("Portfolio updated:", newVal);
    }

    get sortedPortfolioVersions() {
        return (this.portfolio.portfolioVersions || []).sort((a, b) => {
            // Ensure date_version is a valid date string
            return new Date(a.date_version).getTime() - new Date(b.date_version).getTime();
        });
    }
}
